import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/method`;
const API_TOKEN = "Bearer " + process.env.REACT_APP_PRODUCT_API_TOKEN;

// Async thunk for fetching categories
export const get_category = createAsyncThunk(
  "product/get_category",
  async (_, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_item_groups`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_topsellingproducts = createAsyncThunk(
  "product/get_topsellingproducts",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_top_selling_products?page=${page}&page_size=${size}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_limitedtimeofferproducts = createAsyncThunk(
  "product/get_limitedtimeofferproducts",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Limited Time Offer&page=${page}&page_size=${size}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const get_specialdiscount = createAsyncThunk(
  "product/get_specialdiscount",
  async (
    { page = 1, size = 20 },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_special_discount_items?page=${page}&page_size=${size}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_newproducts = createAsyncThunk(
  "product/get_newproducts",
  async (
    { page = 1, size = 20 },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_new_website_items?page=${page}&page_size=${size}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_hotdeals = createAsyncThunk(
  "product/get_hotdeals",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Hot Deal&page=${page}&page_size=${size}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_searchproducts = createAsyncThunk(
  "product/get_searchproducts",
  async (
    { value, page, size },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.search?query=${value}&page=${page}&page_size=${size}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const get_productdetails = createAsyncThunk(
  "product/get_productdetails",
  async (value, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_website_item_details?item_code=${encodeURIComponent(value)}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_product_variants = createAsyncThunk(
  "product/get_product_variants",
  async (productid, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/webshop.webshop.variant_selector.utils.get_attributes_and_values?item_code=${productid}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      return fulfillWithValue(data);    
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.response.data);
    }
  }
);


export const get_discountproducts = createAsyncThunk(
  "product/get_discountproducts",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      // const { data } = await axios.post(
      //   `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
      //   {
      //     // This is the request body with query arguments
      //     query_args: {
      //       field_filters: {
      //         discount: [25], // discount value as per your requirement
      //       },
      //       attribute_filters: {},
      //       item_group: "",
      //       start: null,
      //       from_filters: false,
      //     },
      //   },
      //   {
      //     headers: {
      //       Authorization: process.env.API_TOKEN,
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );
      // // console.log(data);
      // return fulfillWithValue(data);
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_special_discount_items?page=${page}&page_size=${size}`,
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_frozenfood = createAsyncThunk(
  "product/get_frozenfood",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          // This is the request body with query arguments
          query_args: {
            field_filters: {},
            attribute_filters: {},
            item_group: "Frozen Food",
            start: null,
            from_filters: false,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_riceanddal = createAsyncThunk(
  "product/get_riceanddal",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          query_args: {
            field_filters: {
              item_group: ["Rice", "Dal or Lentil"],
            },
            attribute_filters: {},
            item_group: null,
            start: (page - 1) * 20,
            from_filters: false,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_meatandfish = createAsyncThunk(
  "product/get_meatandfish",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          query_args: {
            field_filters: {},
            attribute_filters: {},
            item_group: "Meat & Fish",
            start: null,
            from_filters: true,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_spices = createAsyncThunk(
  "product/get_spices",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          query_args: {
            field_filters: {},
            attribute_filters: {},
            item_group: "Spices",
            start: null,
            from_filters: true,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_beverages = createAsyncThunk(
  "product/get_beverages",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          query_args: {
            field_filters: {},
            attribute_filters: {},
            item_group: "Beverages",
            start: null,
            from_filters: false,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_snacks = createAsyncThunk(
  "product/get_snacks",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          query_args: {
            field_filters: {},
            attribute_filters: {},
            item_group: "Snacks",
            start: null,
            from_filters: false,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_dailyproducts = createAsyncThunk(
  "product/get_dailyproducts",
  async ({ page, size }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          query_args: {
            field_filters: {},
            attribute_filters: {},
            item_group: "Local Product",
            start: null,
            from_filters: true,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const get_products_by_category = createAsyncThunk(
  "product/get_products_by_category",
  async (
    { category, page, size },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {       
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.api.get_product_filter_data?page=${page}&page_size=${size}`,
        {
          query_args: {
            field_filters: {},
            attribute_filters: {},
            item_group: category,
            start: (page - 1) * 20,
            from_filters: false,
          },
        },
        {
          headers: {
            Authorization: API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.auth_api.change_own_password`,
        info, // This is the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.auth_api.reset_password`,
        info, // This is the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllOrders = createAsyncThunk(
  "auth/getAllOrders",
  async ({ page, size }, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.customer_api.get_customer_past_orders?page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const update_profile = createAsyncThunk(
  "auth/update_profile",
  async (value, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.customer_api.manage_customer_profile`,
        {
          profile: value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_userinfo = createAsyncThunk(
  "auth/get_userinfo",
  async (value, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.customer_api.get_own_customer_profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_customer_order_detail = createAsyncThunk(
  "auth/get_customer_order_detail",
  async ({ orderId }, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.customer_api.get_order_details_by_id?order_id=${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      console.log(data);

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const re_order = createAsyncThunk(
  "auth/re_order",
  async (value, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.customer_api.reorder_quotation`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_flash_sales = createAsyncThunk(
  "auth/get_flash_sales",
  async ({ page, size }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Flash Sale&page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_TOKEN,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      console.log(error);

      return rejectWithValue(error);
    }
  }
);

export const get_special_discount = createAsyncThunk(
  "auth/get_special_discount",
  async ({ page, size }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Special Discount&page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_TOKEN,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_summer_sale = createAsyncThunk(
  "auth/get_summer_sale",
  async ({ page, size }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Summer Sale&page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_TOKEN,
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_beverage_offer = createAsyncThunk(
  "auth/get_beverage_offer",
  async ({ page, size }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Beverage's Offer&page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_TOKEN,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_natural_spices_offer = createAsyncThunk(
  "auth/get_natural_spices_offer",
  async ({ page, size }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Natural Spices&page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_TOKEN,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_deal_of_the_month = createAsyncThunk(
  "auth/get_deal_of_the_month",
  async ({ page, size }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_offer_items?offer_title=Best Deals Of the Week&page=${page}&page_size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_TOKEN,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_delivery_slot = createAsyncThunk(
  "auth/get_delivery_slot",
  async ({ type }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.cart_api.get_delivery_slot?delivery_type=${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: API_TOKEN,
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_are_coverage = createAsyncThunk(
  "auth/get_are_coverage",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_coverage_area_info`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const upload_profile_photo = createAsyncThunk(
  "auth/upload_profile_photo",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    const token = "Bearer " + localStorage.getItem("userToken");
    try {
      const { data } = await axios.post(
        `${BASE_URL}/keno_store.customer_api.update_profile_picture`,
        info, // Send FormData as the request body
        {
          headers: {
            Authorization: token, // Add token to the Authorization header
          },
        }
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const get_shipping_lists = createAsyncThunk(
  "auth/get_shipping_lists",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    // const token = localStorage.getItem("userToken");
    try {
      const { data } = await axios.get(
        `${BASE_URL}/keno_store.api.get_shipping_allowed_neighborhood`
      );

      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Slice for managing home-related state
const HomeReducer = createSlice({
  name: "HomeReducer",
  initialState: {
    categories: [],
    top_selling_products: [],
    limited_time_offer_products: [],
    special_discount_products: [],
    new_products: [],
    products: [],
    hot_deals: [],
    search_products: [],
    current_product_detail: {},
    discount_products: [],
    frozen_foods: [],
    riceanddal: [],
    meatandfish: [],
    spices: [],
    beverages: [],
    snacks: [],
    daily_products: [],
    loading: true,
    mainloading: false,
    all_products: [],
    category_products: [],
    user_info: {},
    orderDetails: [],
    flash_sale: [],
    special_discount: [],
    summer_sale: [],
    beverage_offer: [],
    natural_spices_offer: [],
    deal_of_the_month: [],
    area_coverage: {},
    delivery_slots: [],
    successMessage: "",
    errorMessage: "",
    neibourhoods: {},
    variants: [],
  },
  reducers: {
    messageClear: (state) => {
      state.successMessage = "";
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_category.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_category.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.categories = payload.data.item_groups;
      })
      .addCase(get_category.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_topsellingproducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_topsellingproducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.top_selling_products = payload.data.items;
      })
      .addCase(get_topsellingproducts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_limitedtimeofferproducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_limitedtimeofferproducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.limited_time_offer_products = payload.data.items;
      })
      .addCase(get_limitedtimeofferproducts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_specialdiscount.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_specialdiscount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.special_discount_products = payload.message.items;
      })
      .addCase(get_specialdiscount.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_newproducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_newproducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.new_products = payload.message.items;
      })
      .addCase(get_newproducts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_hotdeals.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_hotdeals.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.hot_deals = payload.data.items;
      })
      .addCase(get_hotdeals.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_searchproducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_searchproducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.search_products = payload.data.items;
      })
      .addCase(get_searchproducts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_productdetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_productdetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.current_product_detail = payload.message;
      })
      .addCase(get_productdetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_product_variants.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_product_variants.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.variants = payload.message;
      })
      .addCase(get_product_variants.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_discountproducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_discountproducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.discount_products = payload.message.items;
        state.discount_products = payload.message.items;
      })
      .addCase(get_discountproducts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_frozenfood.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_frozenfood.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.frozen_foods = payload.message.items;
      })
      .addCase(get_frozenfood.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_riceanddal.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_riceanddal.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.riceanddal = payload.message.items;
      })
      .addCase(get_riceanddal.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_meatandfish.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_meatandfish.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meatandfish = payload.message.items;
      })
      .addCase(get_meatandfish.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_spices.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_spices.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.spices = payload.message.items;
      })
      .addCase(get_spices.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_beverages.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_beverages.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.beverages = payload.message.items;
      })
      .addCase(get_beverages.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_snacks.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_snacks.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.snacks = payload.message.items;
      })
      .addCase(get_snacks.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_dailyproducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_dailyproducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.daily_products = payload.message.items;
      })
      .addCase(get_dailyproducts.rejected, (state) => {
        state.loading = false;
      })

      .addCase(get_products_by_category.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_products_by_category.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.all_products = payload.message.items;
        state.items_count = payload.message.items_count;
      })
      .addCase(get_products_by_category.rejected, (state) => {
        state.loading = false;
      })

      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.errorMessage = payload.response.data.message;
        state.loading = false;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.successMessage = payload.data.message;
        state.loading = false;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.errorMessage = payload.response.data.message;
        state.loading = false;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.successMessage = payload.data.message;
        state.loading = false;
      })

      .addCase(getAllOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOrders.rejected, (state, { payload }) => {
        // state.errorMessage = payload.response.data.message;
        state.loading = false;
      })
      .addCase(getAllOrders.fulfilled, (state, { payload }) => {
        // state.successMessage = payload.data.message;
        state.loading = false;
        state.allOrders = payload.data.orders;
      })

      .addCase(update_profile.pending, (state) => {
        state.loading = true;
      })
      .addCase(update_profile.rejected, (state, { payload }) => {
        // state.errorMessage = payload.response.data.message;
        state.loading = false;
      })
      .addCase(update_profile.fulfilled, (state, { payload }) => {
        state.successMessage = "Profile info updated";
        state.loading = false;
      })

      .addCase(get_userinfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_userinfo.rejected, (state, { payload }) => {
        // state.errorMessage = payload.response.data.message;
        state.loading = false;
      })
      .addCase(get_userinfo.fulfilled, (state, { payload }) => {
        // state.successMessage = "Profile info fetched Successfully"
        state.loading = false;
        state.user_info = payload.data.profile;
      })

      .addCase(get_customer_order_detail.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_customer_order_detail.rejected, (state, { payload }) => {
        // state.errorMessage = payload.response.data.message;
        state.loading = false;
      })
      .addCase(get_customer_order_detail.fulfilled, (state, { payload }) => {
        state.orderDetails = payload.data.order;
        state.loading = false;
      })

      .addCase(re_order.pending, (state) => {
        state.loading = true;
      })
      .addCase(re_order.rejected, (state, { payload }) => {
        state.errorMessage = "Failed to reorder";
        state.loading = false;
      })
      .addCase(re_order.fulfilled, (state, { payload }) => {
        state.successMessage = payload.message.message;
        state.loading = false;
      })

      //deals

      .addCase(get_flash_sales.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_flash_sales.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_flash_sales.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.flash_sale = payload.data.items;
      })

      .addCase(get_special_discount.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_special_discount.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_special_discount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.special_discount = payload.data.items;
      })

      .addCase(get_beverage_offer.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_beverage_offer.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_beverage_offer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.beverage_offer = payload.data.items;
      })

      .addCase(get_summer_sale.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_summer_sale.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_summer_sale.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.summer_sale = payload.data.items;
      })

      .addCase(get_natural_spices_offer.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_natural_spices_offer.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_natural_spices_offer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.natural_spices_offer = payload.data.items;
      })

      .addCase(get_deal_of_the_month.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_deal_of_the_month.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_deal_of_the_month.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.deal_of_the_month = payload.data.items;
      })

      .addCase(get_are_coverage.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_are_coverage.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_are_coverage.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.area_coverage = payload.data;
      })

      .addCase(get_delivery_slot.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_delivery_slot.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(get_delivery_slot.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.delivery_slots = payload.data.delivery_slots;
      })

      .addCase(upload_profile_photo.pending, (state) => {
        state.loading = true;
      })
      .addCase(upload_profile_photo.rejected, (state, { payload }) => {
        state.loading = false;
        state.successMessage = "Faild to Upload Photo!";
      })
      .addCase(upload_profile_photo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = "Profile Picture Updated!";
      })

      .addCase(get_shipping_lists.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_shipping_lists.rejected, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = "Faild to Upload Photo!";
      })
      .addCase(get_shipping_lists.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.neibourhoods = payload.data.neighborhoods
        // state.successMessage = "Profile Picture Updated!";
      })
  },
});

export const { messageClear } = HomeReducer.actions;
export default HomeReducer.reducer;
